var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-cover" },
    [
      _c(
        "el-input",
        {
          class: _vm.indicantValue ? "img_status" : "",
          attrs: {
            value: _vm.value,
            placeholder: _vm.option.description,
            disabled: _vm.optionReadonly || _vm.typeOperate,
            onkeyup:
              "value=value.replace(/[^-\\d.]/g, '').replace(/^-+/, '-').replace(/\\.{2,}/g, '.')"
          },
          on: { input: _vm.onChangeHandler }
        },
        [
          _vm.unitsArr.length === 1
            ? _c("template", { slot: "append" }, [
                _vm._v(_vm._s(_vm.option.units))
              ])
            : _vm._e(),
          _vm.unitsArr.length > 1
            ? _c(
                "el-select",
                {
                  attrs: { slot: "append", placeholder: "请选择" },
                  slot: "append",
                  model: {
                    value: _vm.chooseUnits,
                    callback: function($$v) {
                      _vm.chooseUnits = $$v
                    },
                    expression: "chooseUnits"
                  }
                },
                [
                  _c("template", { slot: "prefix" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.unitsArr.find(function(item) {
                            return item === _vm.chooseUnits
                          }) || ""
                        ) +
                        " "
                    )
                  ]),
                  _vm._l(_vm.unitsArr, function(item) {
                    return _c("el-option", {
                      key: item,
                      attrs: { label: item, value: item }
                    })
                  })
                ],
                2
              )
            : _vm._e()
        ],
        2
      ),
      _vm.indicantValue == "high"
        ? _c("img", {
            style: "left:" + _vm.option.width + "%",
            attrs: {
              src: require("../../../assets/img/high.png"),
              height: "25px"
            }
          })
        : _vm._e(),
      _vm.indicantValue == "low"
        ? _c("img", {
            style: "left:" + _vm.option.width + "%",
            attrs: {
              src: require("../../../assets/img/low.png"),
              height: "25px"
            }
          })
        : _vm._e(),
      _vm.showStartDoubtIcon && !_vm.showReplyIcon
        ? _c("img", {
            staticStyle: { "margin-left": "5px", cursor: "pointer" },
            attrs: {
              src: require("@/assets/img/start_question.png"),
              width: "15",
              height: "15"
            },
            on: { click: _vm.showDoubtDialog }
          })
        : _vm._e(),
      _vm.showReplyIcon
        ? _c("img", {
            staticStyle: { "margin-left": "5px", cursor: "pointer" },
            attrs: {
              src: require("@/assets/img/reply_question.png"),
              width: "15",
              height: "15"
            },
            on: {
              click: function($event) {
                return _vm.showDoubtDialog()
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }