var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-button",
        {
          staticClass: "addTable",
          attrs: { disabled: _vm.optionReadonly || _vm.typeOperate },
          on: { click: _vm.onAddHandler }
        },
        [_vm._v("新增一条")]
      ),
      _c(
        "el-table",
        { ref: "renderTable", attrs: { data: _vm.list } },
        [
          _vm._l(_vm.columns, function(col) {
            return _c("el-table-column", {
              key: col.prop,
              attrs: {
                label: col.label,
                prop: col.prop,
                "min-width": _vm.calculateMinWidth(col)
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        col.type === "link"
                          ? _c(
                              "el-link",
                              {
                                attrs: {
                                  type: "primary",
                                  href:
                                    scope.row[col.prop] ||
                                    col.option.defaultValue,
                                  target: "_blank"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    col.title ||
                                      scope.row[col.prop] ||
                                      col.option.defaultValue
                                  )
                                )
                              ]
                            )
                          : _vm._e(),
                        col.type != "link"
                          ? _c("el-crf-" + col.type, {
                              tag: "component",
                              attrs: {
                                option: col.option,
                                value: scope.row[col.prop],
                                showStartDoubtIcon: _vm.showDoubtIcon,
                                showReplyDoubtIcon: _vm.isReplayDoubt(col.prop),
                                groupInfo: {
                                  name: col.prop,
                                  groupName: _vm.option.name,
                                  valueIndex: scope.$index
                                }
                              },
                              on: {
                                input: function($event) {
                                  return _vm.valueChange($event, scope, col)
                                },
                                OpenDoubtDialog: function($event) {
                                  return _vm.OpenDoubtDialog(col, scope.$index)
                                }
                              }
                            })
                          : _vm._e()
                      ]
                    }
                  }
                ],
                null,
                true
              )
            })
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              align: "center",
              width: "90px",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "deleteBtn",
                        attrs: {
                          size: "small",
                          disabled: _vm.optionReadonly || _vm.typeOperate
                        },
                        nativeOn: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.onRemoveHandler(scope.$index)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }