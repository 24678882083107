import { render, staticRenderFns } from "./project.vue?vue&type=template&id=0654434d&"
import script from "./project.vue?vue&type=script&lang=js&"
export * from "./project.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\workSpace\\fozo-medical-research-cloud-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0654434d')) {
      api.createRecord('0654434d', component.options)
    } else {
      api.reload('0654434d', component.options)
    }
    module.hot.accept("./project.vue?vue&type=template&id=0654434d&", function () {
      api.rerender('0654434d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layout/project.vue"
export default component.exports