var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cover" },
    [
      _c(
        "el-select",
        {
          attrs: {
            value: _vm.value || _vm.defaultValue,
            clearable: "",
            placeholder: _vm.option.placeholder,
            disabled: _vm.optionReadonly || _vm.typeOperate,
            filterable: ""
          },
          on: { input: _vm.onChangeHandler, blur: _vm.onInputChange }
        },
        _vm._l(_vm.list, function(item) {
          return _c("el-option", {
            key: item.value,
            attrs: { label: item.label, value: item.value }
          })
        }),
        1
      ),
      _vm.showStartDoubtIcon && !_vm.showReplyIcon
        ? _c("img", {
            staticStyle: { "margin-left": "5px", cursor: "pointer" },
            attrs: {
              src: require("@/assets/img/start_question.png"),
              width: "15",
              height: "15"
            },
            on: { click: _vm.showDoubtDialog }
          })
        : _vm._e(),
      _vm.showReplyIcon
        ? _c("img", {
            staticStyle: { "margin-left": "5px", cursor: "pointer" },
            attrs: {
              src: require("@/assets/img/reply_question.png"),
              width: "15",
              height: "15"
            },
            on: {
              click: function($event) {
                return _vm.showDoubtDialog()
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }