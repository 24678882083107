function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState, mapMutations } from 'vuex';
export default {
  props: {
    value: {
      type: [String, Number],
      required: false
    },
    option: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      }
    },
    showStartDoubtIcon: {
      type: Boolean,
      default: false
    },
    showReplyDoubtIcon: {
      type: Boolean,
      default: false
    },
    groupInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      minopValue: 0,
      maxopValue: 10,
      initStep: 1,
      marks: {},
      optionReadonly: false
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState('study', ['typeOperate'])), mapState('crf', ['detail'])), {}, {
    showReplyIcon: function showReplyIcon() {
      var _this = this;

      var groupType = null;

      if (this.groupInfo.groupName) {
        if (this.detail.crfDoubts.length != 0) {
          groupType = this.detail.crfDoubts.some(function (item) {
            return item.itemName === _this.groupInfo.name && item.groupIndex === _this.groupInfo.valueIndex;
          });
        }
      } else {
        groupType = true;
      }

      return this.showReplyDoubtIcon && groupType;
    }
  }),
  watch: {
    option: {
      handler: function handler(v) {
        var _this2 = this;

        if (v && v.readOnly && v.readOnly === 1) {
          this.optionReadonly = true;
        } else {
          this.optionReadonly = false;
        }

        var valueArr = v.optionsValue.split(','); // const textArr = v.optionsText.split(',')
        //  配置mark

        valueArr.forEach(function (element, idx) {
          _this2.marks[element] = element;
        }); // 最大值，最小值

        if (v.valueMax) {
          this.maxopValue = v.valueMax;
        } else {
          this.maxopValue = Number(valueArr[valueArr.length - 1]);
        }

        if (v.valueMin) {
          this.maxopValue = v.valueMin;
        } else {
          this.minopValue = Number(valueArr[0]);
        } // 步长


        if (v.valueStep) {
          this.initStep = Number(v.valueStep);
        }

        var params = _objectSpread(_objectSpread(_objectSpread({
          type: 'add'
        }, this.groupInfo), v.validationEx && {
          validationEx: v.validationEx
        }), v.validationErrorMsg && {
          validationErrorMsg: v.validationErrorMsg
        });

        this.changeSaveItemSuc(params);
      },
      immediate: true
    }
  },
  methods: _objectSpread(_objectSpread({}, mapMutations('crf', ['changeSaveItemSuc'])), {}, {
    onChangeHandler: function onChangeHandler(v) {
      if (!(v === 0 && typeof this.value === 'undefined')) {
        this.$emit('input', v);
      }
    },
    showDoubtDialog: function showDoubtDialog() {
      // 打开质疑弹窗
      this.$emit('OpenDoubtDialog');
    }
  }),
  beforeDestroy: function beforeDestroy() {
    this.$emit('input', undefined);
    this.changeSaveItemSuc(_objectSpread({
      type: 'remove'
    }, this.groupInfo));
  }
};