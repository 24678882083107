var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Dialog",
    {
      attrs: {
        visible: _vm.visible,
        option: _vm.option,
        width: _vm.param.doubtId ? "600px" : "500px",
        handleBeforeClose: _vm.handleClose
      },
      on: { handleClose: _vm.handleClose }
    },
    [
      _c(
        "span",
        {
          staticClass: "dialog-title",
          attrs: { slot: "title" },
          slot: "title"
        },
        [_c("span", [_vm._v("质疑")])]
      ),
      [
        _vm.param.doubtId
          ? _c("div", { staticClass: "reply-form" }, [
              _c(
                "div",
                { staticClass: "history-msg" },
                _vm._l(_vm.doubtRecordList, function(msg) {
                  return _c(
                    "div",
                    { key: msg.doubtRecordId, staticClass: "msg" },
                    [
                      _c("div", { staticClass: "msg-header" }, [
                        msg.doubtType === 0
                          ? _c("span", { staticClass: "header-name yelow" }, [
                              _vm._v("系统质疑")
                            ])
                          : _vm._e(),
                        msg.doubtType === 1
                          ? _c("span", { staticClass: "header-name" }, [
                              _vm._v(_vm._s(msg.userName))
                            ])
                          : _vm._e(),
                        msg.optionType === 0
                          ? _c("span", { staticClass: "gray" }, [
                              _vm._v("[开启] ")
                            ])
                          : msg.optionType === 1
                          ? _c("span", { staticClass: "gray" }, [
                              _vm._v("[关闭] ")
                            ])
                          : msg.optionType === 2
                          ? _c("span", { staticClass: "gray" }, [
                              _vm._v("[回复] ")
                            ])
                          : _vm._e(),
                        _c("span", { staticClass: "header-time" }, [
                          _vm._v(_vm._s(msg.createDate))
                        ])
                      ]),
                      _c("div", { staticClass: "msg-content" }, [
                        _c("p", [_vm._v(_vm._s(msg.doubtDescription))])
                      ])
                    ]
                  )
                }),
                0
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showReplyContent,
                      expression: "showReplyContent"
                    }
                  ],
                  staticClass: "send-box"
                },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", placeholder: "请输入内容" },
                    model: {
                      value: _vm.doubtContent,
                      callback: function($$v) {
                        _vm.doubtContent = $$v
                      },
                      expression: "doubtContent"
                    }
                  }),
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "100px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.replyDoubtFuc }
                    },
                    [_vm._v("回复质疑")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "auth",
                          rawName: "v-auth",
                          value: "study.iconBtn.doubt",
                          expression: "'study.iconBtn.doubt'"
                        }
                      ],
                      staticClass: "close-btn",
                      staticStyle: { width: "100px" },
                      on: { click: _vm.handleCloseDoubt }
                    },
                    [_vm._v("关闭质疑")]
                  )
                ],
                1
              )
            ])
          : _c(
              "div",
              { staticClass: "start-form" },
              [
                _c(
                  "el-form",
                  { attrs: { "label-position": "top" } },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "doubt-item",
                        attrs: { label: "质疑对象" }
                      },
                      [
                        _c("p", [_vm._v(_vm._s(_vm.doubtTitle))]),
                        _c("el-input", {
                          staticClass: "doubt-value",
                          attrs: {
                            type: "textarea",
                            value: _vm.doubtValue,
                            readonly: ""
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "质疑内容" } },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            placeholder: "请输入内容"
                          },
                          model: {
                            value: _vm.doubtContent,
                            callback: function($$v) {
                              _vm.doubtContent = $$v
                            },
                            expression: "doubtContent"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { width: "100px" },
                            attrs: { type: "primary" },
                            on: { click: _vm.startDoubt }
                          },
                          [_vm._v("开启质疑")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
      ],
      _c("span", { attrs: { slot: "footer" }, slot: "footer" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }