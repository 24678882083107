export function getFileName(url) {
  var execed = /\/([^/]+$)/.exec(url);
  if (!execed) return '';
  return execed[1];
}
export function replaceUrl(url) {
  var reUrl;
  var urlObject = new URL(url);

  if (window.location.host !== urlObject.origin) {
    reUrl = 'http://' + window.location.host + urlObject.pathname;
  }

  return reUrl;
}
var TYPE_NUMBER = ['int', 'float'];
var REG_PHONE = new RegExp(/^1[3456789]\d{9}$/);

function validateIdCard(val) {
  var p = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
  var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
  var parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2];
  var code = val.substring(17);

  if (p.test(val)) {
    var sum = 0;

    for (var i = 0; i < 17; i++) {
      sum += val[i] * factor[i];
    }

    if (parity[sum % 11] == code.toUpperCase()) {
      return true;
    }
  }

  return false;
} // function validatePhone(val) {
//   var p = /^1[3456789]\d{9}$/
//   if (p.test(val)) {
//     return true
//   }
//   return false
// }


export function parseValidateRules(type, option) {
  if (!type || !option) return [];
  var rules = [];

  if (option.required === 1) {
    rules.push({
      required: true,
      message: "\u8BF7".concat(option.optionsValue ? '选择选项' : '输入数据'),
      trigger: ['blur', 'change']
    });
  }

  if (!option.validate) return rules;

  if (TYPE_NUMBER.includes(option.validate.type)) {
    // int float型校验
    rules.push({
      validator: function validator(rule, value, callback) {
        if (value) {
          if (option.validate.type === 'int') {
            if (!/^[-+]?\d+$/.test(value)) {
              callback(new Error(option.validate.errorMsg));
              return;
            }
          } else if (option.validate.type === 'float') {
            if (!/^[-+]?\d*(\.\d+)?$/.test(value)) {
              callback(new Error(option.validate.errorMsg));
              return;
            }

            if (option.validate.precision) {
              var regex = new RegExp("^[-+]?\\d+(\\.\\d{".concat(0, ",", option.validate.precision, "})?$"));

              if (!regex.test(value)) {
                callback(new Error(option.validate.errorMsg));
                return;
              }
            }
          }

          if (option.validate.min && +value < +option.validate.min) {
            callback(new Error(option.validate.errorMsg));
            return;
          }

          if (option.validate.max && +value > +option.validate.max) {
            callback(new Error(option.validate.errorMsg));
            return;
          }

          callback();
        } else {
          callback();
        }
      },
      message: option.validate.errorMsg,
      trigger: ['blur', 'change']
    });
  } else if (option.validate.type === 'email') {
    rules.push({
      type: 'email',
      message: option.validate.errorMsg,
      trigger: ['blur', 'change'],
      required: false
    });
  } else if (option.validate.type === 'phone') {
    rules.push({
      pattern: REG_PHONE,
      message: option.validate.errorMsg,
      trigger: ['blur', 'change']
    });
  } else if (option.validate.type === 'idcard') {
    rules.push({
      validator: function validator(rule, value, callback) {
        if (value) {
          if (validateIdCard(value)) {
            callback();
          } else {
            callback(new Error(option.validate.errorMsg));
          }
        } else {
          callback();
        }
      },
      trigger: ['blur', 'change']
    });
  }

  if ((option.validate.min || option.validate.max) && !TYPE_NUMBER.includes(option.validate.type)) {
    var rule = {
      message: option.validate.errorMsg,
      trigger: ['blur', 'change']
    };

    if (option.validate.min) {
      rule['min'] = +option.validate.min;
    }

    if (option.validate.max) {
      rule['max'] = +option.validate.max;
    }

    rules.push(rule);
  }

  if (option.validate.regexp) {
    rules.push({
      pattern: new RegExp(option.validate.regexp),
      message: option.validate.errorMsg
    });
  }

  return rules;
}
/**
 *
 * type 区分是什么类型的format
 * rule [] 第一项表位置，第二项表format内容
 */

export function formatInput(type, rule, value) {
  rule = JSON.parse(rule);

  if (type === 'decimal') {
    // 在后两位位置插入小数点
    if (value.indexOf('.') != -1) {
      // return ((value * 1000) * 0.01).toFixed(2)
      var site = value.length - 1 - value.indexOf('.');
      return (value * Math.pow(10, site) * 0.01).toFixed(2);
    } else {
      return value * 0.01;
    }
  } else if (type === 'interval') {
    // 每间隔多少位，插入什么符号
    if (rule[0] === 1 && rule[1] === '-') {
      var reg = /(\d)(?=(?:\d{1})+$)/g;
      var newNumber = value.toString().replace(reg, '$1-');
      return newNumber;
    }
  }
}