import Vue from 'vue';
import Router from 'vue-router';
import Layout from '@/layout';
import ProjectLayout from '@/layout/project';
import CrfLayout from '@/layout/CrfLayout'; // 解决element-ui导航栏中的vue-router在3.0版本以上重复点击菜单报错问题

var originalPush = Router.prototype.push;

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(function (err) {
    return err;
  });
}; // 把router注册到vue身上， 手动安装


Vue.use(Router); // 空路由组件

var emptyComponent = {
  render: function render(h) {
    return h('router-view');
  }
}; // 项目列表的路由 需要传入id

var projectRoutes = {
  path: '/project',
  component: ProjectLayout,
  children: [{
    path: ':id/home',
    name: 'project.home',
    component: function component() {
      return import('@/views/home');
    },
    meta: {
      title: '首页',
      affix: true,
      perm: 'home',
      noAuth: true,
      layout: 'noBreadcrumb',
      icon: 'home'
    }
  }, {
    path: ':id/subject',
    name: 'project.subject',
    component: function component() {
      return import('@/views/subject');
    },
    meta: {
      title: '患者列表',
      icon: 'test'
    }
  }, {
    path: ':id/follow',
    name: 'project.follow',
    meta: {
      title: '随访',
      icon: 'follow',
      perm: 'follow'
    }
  }, {
    path: ':id/follow/record',
    name: 'project.follow.record',
    meta: {
      title: '随访记录',
      icon: 'mark'
    },
    component: function component() {
      return import('@/views/follow/record');
    }
  }, {
    path: ':id/follow/plan',
    name: 'project.follow.case',
    meta: {
      title: '随访方案',
      icon: 'case'
    },
    component: function component() {
      return import('@/views/follow/plan');
    }
  }, {
    path: ':id/calendar',
    name: 'project.calendar',
    component: function component() {
      return import('@/views/fullcalendar');
    },
    meta: {
      title: '日历',
      icon: 'calendar'
    }
  }, {
    path: ':id/retrieval',
    name: 'project.retrieval',
    component: function component() {
      return import('@/views/search');
    },
    meta: {
      title: '数据检索',
      icon: 'data'
    }
  }, {
    path: ':id/randomization',
    name: 'project.randomization',
    component: function component() {
      return import('@/views/randomization');
    },
    meta: {
      title: '随机结果',
      icon: 'random'
    }
  }, // {
  //   path: ':id/role',
  //   name: 'project.role',
  //   meta: {
  //     title: '项目角色', // 做了配置这是网页的title，循环中已经显示
  //     icon: 'role'
  //   },
  //   component: () => import('@/views/role')
  // }, {
  //   path: ':id/user',
  //   name: 'project.user',
  //   meta: {
  //     title: '项目人员', // 做了配置这是网页的title，循环中已经显示
  //     icon: 'people'
  //   },
  //   component: () => import('@/views/user')
  // },
  {
    path: ':id/doubtList',
    name: 'project.doubtList',
    component: function component() {
      return import('@/views/doubtList');
    },
    meta: {
      title: '质疑列表',
      icon: 'crf'
    }
  }, {
    path: ':id/weChat',
    name: 'project.weChat',
    meta: {
      title: '微信管理',
      icon: 'wechat'
    }
  }, {
    path: ':id/wechat/disease',
    name: 'project.weChat.disease',
    meta: {
      title: '疾病知识',
      icon: 'manage'
    },
    component: function component() {
      return import('@/views/wechat/disease');
    }
  }, {
    path: ':id/wechat/expert',
    name: 'project.weChat.expert',
    meta: {
      title: '专家管理',
      icon: 'manage'
    },
    component: function component() {
      return import('@/views/wechat/expert');
    }
  }, {
    path: ':id/wechat/department',
    name: 'project.weChat.department',
    meta: {
      title: '项目介绍',
      icon: 'manage'
    },
    component: function component() {
      return import('@/views/wechat/department');
    }
  }, {
    path: ':id/wechat/consult',
    name: 'project.weChat.consult',
    meta: {
      title: '咨询管理',
      icon: 'manage'
    },
    component: function component() {
      return import('@/views/wechat/consult');
    }
  }, {
    path: ':id/wechat/wxuser',
    name: 'project.weChat.wxuser',
    meta: {
      title: '用户管理',
      icon: 'manage'
    },
    component: function component() {
      return import('@/views/wechat/wxuser');
    }
  }, {
    path: ':id/wechat/medical',
    name: 'project.weChat.medical',
    meta: {
      title: '病历管理',
      icon: 'manage'
    },
    component: function component() {
      return import('@/views/wechat/medical');
    }
  }]
}; // 一级目录路由

var subjectRoutes = {
  path: '/',
  component: Layout,
  children: [{
    path: '/studyList',
    name: 'studyList',
    meta: {
      title: '项目列表',
      icon: 'subject'
    },
    component: function component() {
      return import('@/views/study');
    } // 跳转的页面路径，在view里面

  }]
}; // 项目管理路由

var studyRoutes = {
  path: '/study',
  component: Layout,
  name: 'study',
  meta: {
    icon: 'project'
  },
  children: [{
    path: 'operation',
    name: 'study.operation',
    meta: {
      title: '操作记录',
      // 做了配置这是网页的title，循环中已经显示
      icon: 'doing'
    },
    component: function component() {
      return import('@/views/sys/record');
    }
  }, {
    path: 'list',
    name: 'study.list',
    meta: {
      title: '项目列表',
      // 做了配置这是网页的title，循环中已经显示
      icon: 'list'
    },
    component: function component() {
      return import('@/views/sys/study/index');
    }
  }, {
    path: 'config',
    name: 'study.list.config',
    meta: {
      title: '项目配置'
    },
    component: function component() {
      return import('@/views/sys/study/studyConfigs');
    }
  }, {
    path: 'crf',
    name: 'study.crf',
    meta: {
      title: 'CRF管理',
      // 做了配置这是网页的title，循环中已经显示
      icon: 'crf'
    },
    component: function component() {
      return import('@/views/sys/crf');
    }
  }, {
    path: 'crfStage',
    name: 'study.ecrf',
    meta: {
      title: 'ECRF',
      icon: 'crf'
    },
    component: function component() {
      return import('@/views/sys/crfStage');
    }
  }, {
    path: 'crfStage/set/',
    name: 'crfStage.set',
    meta: {
      title: 'ECRF设置页' // 做了配置这是网页的title，循环中已经显示

    },
    component: function component() {
      return import('@/views/sys/crfStage/stagePage');
    }
  }, {
    path: ':id/role',
    name: 'project.role',
    meta: {
      title: '项目角色',
      // 做了配置这是网页的title，循环中已经显示
      icon: 'role'
    },
    component: function component() {
      return import('@/views/role');
    }
  }, {
    path: ':id/user',
    name: 'project.user',
    meta: {
      title: '项目人员',
      // 做了配置这是网页的title，循环中已经显示
      icon: 'people'
    },
    component: function component() {
      return import('@/views/user');
    }
  }]
}; // 系统设置路由

var systemRoutes = {
  path: '/sys',
  component: Layout,
  name: 'sys',
  meta: {
    icon: 'setting'
  },
  children: [{
    path: 'organization',
    name: 'sys.organization',
    meta: {
      title: '机构管理',
      // 做了配置这是网页的title，循环中已经显示
      perm: 'sys:org:organization',
      icon: 'organize'
    },
    component: function component() {
      return import('@/views/sys/org');
    }
  }, {
    path: 'role',
    name: 'sys.role',
    meta: {
      title: '角色管理',
      // 做了配置这是网页的title，循环中已经显示
      perm: 'sys:org:role',
      icon: 'job'
    },
    component: function component() {
      return import('@/views/sys/role');
    }
  }, {
    path: 'menu',
    name: 'sys.menu',
    meta: {
      title: '菜单管理',
      // 做了配置这是网页的title，循环中已经显示
      perm: 'sys:org:menu',
      icon: 'menu'
    },
    component: function component() {
      return import('@/views/sys/menu');
    }
  }, {
    path: 'user',
    name: 'sys.user',
    // 这里的那么要和后台的code保持一致，后台是一层就用一层
    meta: {
      title: '用户管理',
      perm: 'sys:org:user',
      icon: 'person'
    },
    component: function component() {
      return import('@/views/sys/user');
    } // 跳转的页面路径，在view里面

  }]
};
var crfRoutes = {
  path: '/crf',
  name: 'crf',
  component: CrfLayout,
  children: [{
    path: ':subjectRecordId',
    name: 'crf.record',
    component: function component() {
      return import('@/views/crf/detail');
    }
  }, {
    path: ':crfId',
    name: 'crf.detail',
    meta: {
      title: 'crf数据'
    },
    component: function component() {
      return import('@/views/home');
    }
  }]
};
export var routes = [projectRoutes, subjectRoutes, studyRoutes, systemRoutes, crfRoutes, {
  path: '/login',
  name: 'login',
  component: function component() {
    return import('@/views/login/index');
  },
  // component: () => import('@/views/login/customizedLogin'),
  meta: {
    hidden: true,
    noAuth: true
  }
}, {
  path: '/meil',
  name: 'meil',
  component: function component() {
    return import('@/views/meil/index');
  },
  meta: {
    hidden: true,
    noAuth: true
  }
}];
export function injectEmptyComp() {
  var routes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  routes.forEach(function (route) {
    route.component = route.component || emptyComponent;
    route.children && injectEmptyComp(route.children);
  });
  return routes;
}

var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // 需要服务器支持
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: routes
  });
};

var router = createRouter();
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher;
}
export default router;