var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cover" },
    [
      _c("el-date-picker", {
        staticClass: "crf-date",
        attrs: {
          type: _vm.option.format ? _vm.option.format : "date",
          value: _vm.value,
          "value-format": _vm.valueFormat,
          placeholder: _vm.option.description,
          disabled: _vm.optionReadonly || _vm.typeOperate
        },
        on: { input: _vm.onChangeHandler }
      }),
      _vm.showStartDoubtIcon && !_vm.showReplyIcon
        ? _c("img", {
            staticStyle: { "margin-left": "5px", cursor: "pointer" },
            attrs: {
              src: require("@/assets/img/start_question.png"),
              width: "15",
              height: "15"
            },
            on: { click: _vm.showDoubtDialog }
          })
        : _vm._e(),
      _vm.showReplyIcon
        ? _c("img", {
            staticClass: "question-icon",
            staticStyle: { "margin-left": "5px", cursor: "pointer" },
            attrs: {
              src: require("@/assets/img/reply_question.png"),
              width: "15",
              height: "15"
            },
            on: {
              click: function($event) {
                return _vm.showDoubtDialog()
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }